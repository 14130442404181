var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, observable, makeObservable } from 'mobx';
var SalesOrderBatchStore = /** @class */ (function () {
    function SalesOrderBatchStore() {
        this.batches = [];
        this.total_quantity = 0;
        this.missing_quantity = 0;
        this.original_quantity = 0;
        makeObservable(this, {
            // Observables
            batches: observable,
            total_quantity: observable,
            missing_quantity: observable,
            original_quantity: observable,
            // Actions
            setBatches: action,
            setBatch: action,
            removeBatch: action,
            resetBatches: action,
            resetTotalQuantity: action,
            getQuantity: action,
            getMissingQuantity: action,
            getOriginalQuantity: action,
        });
    }
    SalesOrderBatchStore.prototype.setBatches = function (batches) {
        this.batches = batches;
    };
    SalesOrderBatchStore.prototype.setBatch = function (order_item, batch) {
        this.batches.push(batch);
        order_item.batches = this.batches;
    };
    SalesOrderBatchStore.prototype.removeBatch = function (order_item, index_batch) {
        this.batches.splice(index_batch, 1);
        this.batches = this.batches.map(function (bt) {
            return bt;
        });
        var batches = this.batches.filter(function (batch) { return batch.order_item === order_item.id; });
        if (batches.length > 0) {
            order_item.batches = this.batches;
        }
        else {
            order_item.batches = [];
        }
    };
    SalesOrderBatchStore.prototype.resetBatches = function () {
        this.batches = [];
    };
    SalesOrderBatchStore.prototype.resetTotalQuantity = function () {
        this.total_quantity = 0;
    };
    SalesOrderBatchStore.prototype.getQuantity = function (order_item_id) {
        return __awaiter(this, void 0, void 0, function () {
            var quantity;
            return __generator(this, function (_a) {
                quantity = 0;
                this.batches.map(function (batch) {
                    if (batch.order_item == order_item_id) {
                        quantity = quantity + batch.quantity;
                    }
                });
                this.total_quantity = quantity;
                return [2 /*return*/];
            });
        });
    };
    SalesOrderBatchStore.prototype.getMissingQuantity = function (quantity_quote_item, total_quantity) {
        this.missing_quantity = quantity_quote_item - total_quantity;
    };
    SalesOrderBatchStore.prototype.getOriginalQuantity = function (quantity) {
        this.original_quantity = quantity;
    };
    return SalesOrderBatchStore;
}());
export default new SalesOrderBatchStore();
