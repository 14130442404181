import React, { useMemo } from 'react';
import { Button, Col, InputNumber, Row, Select, notification } from 'antd';
import { Form } from '@ant-design/compatible';
import SalesOrderStore from '../../../Stores/SalesOrderStore';
import { DeleteOutlined } from '@ant-design/icons';
import { ErrorRender } from '@partsbadger/library';
var NewSampleRequest = function (props) {
    var orderLineItem = props.orderLineItem;
    var _a = props.form, getFieldDecorator = _a.getFieldDecorator, getFieldValue = _a.getFieldValue, setFieldsValue = _a.setFieldsValue;
    var fieldName = function (name, id) {
        return name + '__' + id;
    };
    var order_items = SalesOrderStore.order_items;
    var order = SalesOrderStore.order;
    var updateSamplesOnLineItem = function (id, qty, actionText) {
        if (order && (order === null || order === void 0 ? void 0 : order.line_items)) {
            var payload = order.line_items
                .filter(function (i) { return i.name != 'CNC Part'; })
                .map(function (item) {
                if (item.id === id) {
                    return {
                        id: item.id,
                        quantity_sample_required: qty,
                    };
                }
                return {
                    id: item.id,
                    quantity_sample_required: item.quantity_sample_required,
                };
            });
            SalesOrderStore.update_multiple_items(order.id, payload)
                .then(function (res) {
                var _a;
                setFieldsValue({ partsSelectedForFAA: null, sampleQuantity: null });
                var hasSampleRequests = (_a = res === null || res === void 0 ? void 0 : res.line_items) === null || _a === void 0 ? void 0 : _a.some(function (item) { return item.quantity_sample_required; });
                if (!hasSampleRequests) {
                    SalesOrderStore.update_sales_order_field('ffa_before_production', false).catch(function (error) {
                        notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Unable to update the ffa_before_production field to False',
                            placement: 'bottomRight',
                        });
                    });
                }
                notification.success({
                    message: "Sample Request ".concat(actionText, "d"),
                    description: "The sample was ".concat(actionText.toLowerCase(), "d successfully."),
                });
            })
                .catch(function (error) {
                notification.error({
                    duration: 10,
                    message: 'Error',
                    description: React.createElement(ErrorRender, { error: error }),
                    placement: 'bottomRight',
                });
            });
        }
    };
    var maxSampleQty = function () {
        if (orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.quantity) {
            return orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.quantity;
        }
        var partId = parseInt(getFieldValue('partsSelectedForFAA'));
        var line_item = order === null || order === void 0 ? void 0 : order.line_items.find(function (item) { return item.id === partId; });
        if (line_item) {
            return line_item === null || line_item === void 0 ? void 0 : line_item.quantity;
        }
        return 1;
    };
    var cachedMaxSampleQty = useMemo(maxSampleQty, [orderLineItem, getFieldValue('partsSelectedForFAA')]);
    var hasLineItemsWithNullSampleRequired = function () {
        var _a;
        return (_a = order === null || order === void 0 ? void 0 : order.line_items) === null || _a === void 0 ? void 0 : _a.some(function (item) { return item.quantity_sample_required === null; });
    };
    return (React.createElement(Row, { className: 'row-order mt-5 text-center' },
        React.createElement(Col, { sm: 11 },
            React.createElement(Form.Item, { label: 'Select the Part(s) for FAA:', className: 'pl-8' }, getFieldDecorator((orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id) ? fieldName('partsSelectedForFAA', orderLineItem.id) : 'partsSelectedForFAA', {
                initialValue: orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.name,
                rules: [
                    {
                        required: false,
                        message: 'Please select at least one part for FAA',
                    },
                ],
            })(React.createElement(Select, { disabled: !!(orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id), allowClear: true, style: { width: '100%' }, placeholder: "Please select a part*" }, order_items
                .filter(function (i) { return i.name !== 'CNC Part' && !i.quantity_sample_required; })
                .map(function (entity) {
                var _a;
                return (React.createElement(Select.Option, { key: entity.id, name: entity.name, product_description: entity.product_description, quote_has_product: (_a = entity.quote_has_product) === null || _a === void 0 ? void 0 : _a.id, quantity: entity.quantity, unit_price: entity.unit_price }, entity.name));
            }))))),
        React.createElement(Col, { sm: 11 },
            React.createElement(Form.Item, { label: 'Sample Quantities for FAA*', className: 'pl-8' }, getFieldDecorator((orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id) ? fieldName('sampleQuantity', orderLineItem.id) : 'sampleQuantity', {
                initialValue: orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.quantity_sample_required,
                rules: [
                    {
                        required: getFieldValue('partsSelectedForFAA') && !(orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id) ? true : false,
                        message: 'Please select at a sample quantity',
                    },
                ],
            })(React.createElement(InputNumber, { min: 1, max: cachedMaxSampleQty, style: { width: '100%' }, onBlur: function (e) {
                    if (orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id) {
                        var qty = e.target.value ? parseInt(e.target.value) : null;
                        if (qty && orderLineItem.quantity_sample_required !== qty) {
                            updateSamplesOnLineItem(orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id, qty, 'Update');
                        }
                    }
                } })))),
        (orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id) && (React.createElement(Col, { sm: 2, className: "flex justify-center items-center" },
            React.createElement(Button, { type: "link", onClick: function () {
                    updateSamplesOnLineItem(orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id, null, 'Delete');
                }, icon: React.createElement(DeleteOutlined, { style: {
                        color: 'red',
                    } }) }))),
        !(orderLineItem === null || orderLineItem === void 0 ? void 0 : orderLineItem.id) && hasLineItemsWithNullSampleRequired() && (React.createElement("div", { className: "flex justify-center items-center w-full" },
            React.createElement(Button, { type: "link", onClick: function () {
                    var partId = parseInt(getFieldValue('partsSelectedForFAA'));
                    var qty = parseInt(getFieldValue('sampleQuantity'));
                    if (!partId) {
                        return notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Please select a part to proceed',
                            placement: 'topRight',
                        });
                    }
                    if (!qty) {
                        return notification.error({
                            duration: 10,
                            message: 'Error',
                            description: 'Please enter a quantity to continue',
                            placement: 'topRight',
                        });
                    }
                    updateSamplesOnLineItem(partId, qty, 'Create');
                } }, "Add the Sample Request")))));
};
export default NewSampleRequest;
